import { Animations, Easing } from 'components/atoms/animated-container'

const fadeInConfig = {
  duration: '850ms',
  delay: '150ms',
  ease: Easing.ease,
}

const slideConfig = {
  from: '75px',
  duration: '850ms',
  ease: Easing.ease,
}

export const slideAndFade = [
  Animations.fadeIn(fadeInConfig),
  Animations.slideFromBottom(slideConfig),
]

export const slideAndFadeWithDelay = (delay = '0ms') => [
  Animations.fadeIn({
    ...fadeInConfig,
    delay: `calc(${delay} + 150ms)`,
  }),
  Animations.slideFromBottom({
    ...slideConfig,
    delay,
  }),
]
